document.addEventListener('DOMContentLoaded', (event) => {
    let navItems = document.querySelectorAll('.nav-item a')

    navItems.forEach((navItem) => {
        navItem.onclick = (e) => {
            if (!e.target.attributes.href.value.startsWith('#')) return;

            e.preventDefault()

            let target = e.target.attributes.href.value
            let $el = document.querySelector(target)

            if (!$el) return
            
            window.scrollTo({
                top: $el.offsetTop,
                behavior: 'smooth'
            })
        }
    })
})